<script>
import Banner from "@/views/components/Banner.vue";
import Breadcrumb from "@/views/components/Breadcrumb.vue";
import { tabsOption } from "@/views/contactUs/moke";
import { generateContactTitle, generateTitle } from "@/i18n";

export default {
  name: "index",
  components: {
    Banner,
    Breadcrumb
  },
  data() {
    return {
      tabsOption: tabsOption,
      currentTab: {
        key: "beijing",
        title: "北京总部",
        abbreviation: "北京总部",
        address: "北京市丰台区汉威国际广场三区2号楼8M",
        email: "info@art-bank.net",
        landline: "010-63720131",
        phone: "18710205035",
        src: require("@/assets/contact/beijing.png")
      },
      breadcrumbOption: [] // 面包屑
    };
  },
  mounted() {
    this.checkTab(this.tabsOption[0].key);
  },
  methods: {
    generateContactTitle,
    generateTitle,
    checkTab(key) {
      const tabsOption = this.tabsOption;
      tabsOption.map(item => {
        if (item.key === key) {
          this.currentTab = item;

          // 添加面包屑
          const currentOption = [
            {
              key: "contactUs",
              title: "联系我们",
              toPath: "/contactUs"
            }
          ];
          currentOption.push({
            key: item.key,
            title: item.title,
            toPath: "/contactUs"
          });
          this.breadcrumbOption = currentOption;
        }
      });
    }
  }
};
</script>

<template>
  <div>
    <Banner
      :bannerSrc="require('@/assets/banner/contactUs.jpg')"
      :tabs="tabsOption"
      :selTab="currentTab.key"
    />
    <div class="padding-comm-warp contact_warp">
      <Breadcrumb :breadcrumbList="breadcrumbOption" />
      <div class="content">
        <div class="left">
          <div class="suggestion_warp">
            {{ this.$t("contact.suggestion1") }}<br />
            {{ this.$t("contact.suggestion2") }}<br />
            {{ this.$t("contact.suggestion3") }}<br />
            {{ this.$t("contact.suggestion4") }}<br />
          </div>
          <div class="title">{{ generateTitle(currentTab.key) }}</div>
          <div class="item">
            <el-image :src="require('@/assets/contact/addess.png')" lazy/>
            <span
              >{{ this.$t("contact.address") }}:
              {{ generateContactTitle(currentTab.key) }}</span
            >
          </div>
          <div class="item">
            <el-image :src="require('@/assets/contact/landline.png')" lazy/>
            <span
              >{{ this.$t("contact.telephone") }}:
              {{ currentTab.landline }}</span
            >
          </div>
          <div class="item">
            <el-image :src="require('@/assets/contact/email.png')" />
            <span>{{ this.$t("contact.mail") }}: {{ currentTab.email }}</span>
          </div>
        </div>
        <div class="right">
          <el-image :src="currentTab.src" lazy/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../styles/index.scss";

.more_breadcrumb {
  border-bottom: 1px solid $theme-red;
  padding: 40px 0 10px;
  font-size: 1.2rem;
}

.contact_warp {
  margin-bottom: 40px;
  font-family: SiYuanSongTiRegular;
}

.content {
  display: flex;
  justify-content: space-between;
}
.content .suggestion_warp {
  line-height: 40px;
  //color: #606266;
  padding: 30px 0 0px;
}
.content .left {
  width: 50%;
}
.content .left .title {
  padding: 50px 0 16px;
  font-size: 1.2rem;
}
.content .left .item {
  line-height: 50px;
  .el-image {
    width: 24px;
    height: 24px;
  }
  span {
    margin-left: 6px;
  }
}
.content .right {
  .el-image {
    margin-top: 24px;
    width: 100%;
  }
}
.el-image {
  cursor: pointer;
}
.el-image:hover {
  transform: scale(1.06);
  transition: all 1s;
}
</style>
