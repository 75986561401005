export const tabsOption = [
  {
    key: "macau",
    title: "澳门总部",
    abbreviation: "澳门总部",
    email: "Macao@chinaartbank.org",
    landline: "(853)-8791-9800",
    phone: "13316219735",
    src: require("@/assets/contact/aomen.png")
  },
  {
    key: "hongkong",
    title: "香港分部",
    abbreviation: "香港分部",
    email: "HongKong@chinaartbank.org",
    landline: "(852)-2721-0116",
    phone: "13316219735",
    src: require("@/assets/contact/xianggang.png")
  },
  {
    key: "beijing",
    title: "北京分行",
    abbreviation: "北京分行",
    email: "info@art-bank.net",
    landline: "010-63720131",
    phone: "18710205035",
    src: require("@/assets/contact/beijing.png")
  },
  {
    key: "shanghai",
    title: "上海分部",
    abbreviation: "上海分部",
    email: "Shanghai@chinaartbank.org",
    landline: "(021)-6061-8866",
    phone: "13316219735",
    src: require("@/assets/contact/shanghai.jpg")
  },
  {
    key: "guangzhou",
    title: "广州分部",
    abbreviation: "广州分部",
    email: "info@art-bank.net",
    landline: "020-83759267",
    phone: "13316219735",
    src: require("@/assets/contact/guangzhou.jpg")
  },
  {
    key: "shenzhen",
    title: "深圳分部",
    abbreviation: "深圳分部",
    email: "Shenzhen@chinaartbank.org",
    landline: "(86)152-0161-5410",
    phone: "13316219735",
    src: require("@/assets/contact/shenzhen.png")
  },

  {
    key: "newyork",
    title: "纽约分部",
    abbreviation: "纽约分部",
    email: "NewYork@chinaartbank.org",
    landline: "(212)-875-5358",
    phone: "13316219735",
    src: require("@/assets/contact/niuyue.png")
  },
  {
    key: "sanFrancisco",
    title: "旧金山分部",
    abbreviation: "旧金山分部",
    email: "SanFrancisco@chinaartbank.org",
    landline: "(415)-750-3642",
    phone: "13316219735",
    src: require("@/assets/contact/jiujingshan.png")
  },
  {
    key: "singapore",
    title: "新加坡分部",
    abbreviation: "新加坡分部",
    email: "Singapore@chinaartbank.org",
    landline: "(65)-6688-8868",
    phone: "13316219735",
    src: require("@/assets/contact/xinjiapo.png")
  },
  {
    key: "tokyo",
    title: "东京分部",
    abbreviation: "东京分部",
    email: "Japan@chinaartbank.org",
    landline: "(81)50-5525-2200",
    phone: "13316219735",
    src: require("@/assets/contact/dongjing.png")
  }
];
